<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div
            class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
        >
          <h3>My Vouchers</h3>
          <div>
            <router-link
                :to="{ name: 'buy_voucher' }"
                class="ps-btn btn-sm"
                v-if="auth.isAuthenticated"
            > <i class="icon-cart-add"></i> Buy Voucher
            </router-link>
            &nbsp;&nbsp;
          </div>
        </div>

        <div class="table-responsive">
          <div class="table-responsive">
            <table class="table ps-table ps-table--vendor table-bordered">
              <thead>
              <tr>
                <th>Date</th>
                <th>Voucher Order Number</th>
                <th>Receiver Name</th>
                <th>Total Price</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="voucher in vouchers" :key="voucher.id">
                <td>{{ voucher.created_at | formatDate }}</td>
                <td>
                  <span class="text-muted">{{ voucher.voucher_number }}</span>
                </td>
                <td>{{ voucher.full_name }}</td>
                <td>{{ voucher.balance }}</td>
                <td>{{ voucher.status }}</td>
                <td>
                  <button
                      v-if="voucher.status === 'valid'"
                      class="btn-info btn btn-sm mr-2"
                  >
                    Cancel
                  </button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr
                  v-if="(!voucherData || voucherData.total === 0) && !isLoading"
              >
                <td colspan="6" class="text-center m-4 text-danger p-4">
                  No vouchers available
                </td>
              </tr>
              <tr v-else-if="isLoading">
                <td colspan="6" class="text-center m-4 text-danger">
                  Loading vouchers...
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
  name: "Vouchers",
  data() {
    return {
      voucher: {},
      vouchers: [],
      voucherData: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  watch: {
    $route(to, from) {
      this.getVouchers();
    },
  },
  mounted() {
    this.getVouchers();
  },
  methods: {
    viewVoucher(voucher) {
      this.voucher = voucher;
    },
    getVouchers() {
      this.isLoading = true;
      axios.get("orders/vouchers").then((response) => {
        this.vouchers.length = 0;
        this.vouchers.push(...response.data.data);
        this.voucherData = response.data;
        console.log("Get Vouchers : " + JSON.stringify(this.vouchers));
        this.isLoading = false;
      });
    },
  },
}
</script>

<style scoped>

</style>